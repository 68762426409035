import React from "react";
import clsx from "clsx";
import { useBooking } from "../contexts/booking";
import { Link, useParams } from "react-router-dom";
import client from "../apis";
import { trackData } from "../apis/analytics";
import { useTranslation } from "react-i18next";
import utils, { ACTIONS } from "../utils";

function AvailabilityPage() {
    const { lang, token } = useParams();
    const { business, zone, date, time, seats, setTime } = useBooking();
    const { t } = useTranslation();

    const [selected, setSelected] = React.useState(false);
    const [checkbox, setCheckbox] = React.useState();
    const [slots, setSlots] = React.useState([]);

    React.useEffect(() => {
        /*async function loadSlots() {
            let formatted = date.toISOString().split('T')[0];
            let response = await client.getAvailabilitySlots(token, formatted, time, seats);

            if (response && response.status === 200) {
                setSlots(response.data.data);
            }
        }*/

        async function loadSlotsOld() {
            let formatted = date.toISOString().split('T')[0];
            let response = await client.getAvailabilitySlotsOld(token, formatted, seats);

            if (response && response.status === 200) {
                let filteredTimeSlots = utils.filterTimeSlots(new Date(date), response.data.data);
                setSlots(filteredTimeSlots);
            }
        }

        loadSlotsOld();
    }, [date, token, seats]);

    const onSelectHandler = index => {
        setSelected(true);
        setTime(slots[index].start);
        setCheckbox(index);
    }

    const onClickHanlder = () => {
        trackData(
            business.attributes?.reservation_settings?.[0]?.ris_id, token, lang, ACTIONS.PICK_TIME,
            business.attributes?.reservation_settings?.[0]?.ris_networks,
            { date: date.toISOString().split("T")[0], time: time },
        );
    }

    return (
        <div className="max-w-xl mx-auto m-4 p-8 space-y-4 rounded-md shadow-md">
            <div className="flex items-center">
                <Link to={`/${lang}/${token}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6" viewBox="0 0 512 512">
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M244 400L100 256l144-144M120 256h292" />
                    </svg>
                </Link>

                <p className="flex-1 text-2xl text-center first-letter:capitalize">{t("availabilityPage.p_1")}</p>
            </div>

            <p className="text-2xl text-center">{business.attributes.name}</p>

            <div className="px-4 py-2 flex items-center gap-4 border border-gray-500 rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-8" viewBox="0 0 512 512">
                    <rect fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="32" x="48" y="80" width="416" height="384" rx="48" />
                    <circle cx="296" cy="232" r="24" />
                    <circle cx="376" cy="232" r="24" />
                    <circle cx="296" cy="312" r="24" />
                    <circle cx="376" cy="312" r="24" />
                    <circle cx="136" cy="312" r="24" />
                    <circle cx="216" cy="312" r="24" />
                    <circle cx="136" cy="392" r="24" />
                    <circle cx="216" cy="392" r="24" />
                    <circle cx="296" cy="392" r="24" />
                    <path fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="32" strokeLinecap="round" d="M128 48v32M384 48v32" />
                    <path fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="32" d="M464 160H48" />
                </svg>

                <span className="flex-1 text-center">{date.toLocaleString(lang, { 'weekday': 'long', 'month': 'long', 'day': '2-digit' })}</span>
            </div>

            <div className="px-4 py-2 flex items-center gap-4 border border-gray-500 rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-8" viewBox="0 0 512 512">
                    <path d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" />
                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M256 128v144h96" />
                </svg>

                <span className="flex-1 text-center">{time}</span>
            </div>

            <div className="px-4 py-2 flex items-center gap-4 border border-gray-500 rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-8" viewBox="0 0 512 512">
                    <path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                    <path d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" />
                </svg>

                <span className="flex-1 text-center">{seats} {seats > 1 ? t("availabilityPage.span_1_b") : t("availabilityPage.span_1_a")}</span>
            </div>

            {zone ? <div className="px-4 py-2 flex items-center gap-4 border border-gray-500 rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 0 1-1.125-1.125v-3.75ZM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-8.25ZM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-2.25Z" />
                </svg>

                <span className="flex-1 text-center">{zone}</span>
            </div> : null}

            <div>
                <p className="mb-4 font-bold text-2xl text-center first-letter:capitalize">{t("availabilityPage.p_2")}</p>

                {slots.length === 0 ?
                    <p className="text-center">{t("availabilityPage.p_3")}</p> :
                    <div>
                        <div className="grid gap-4 grid-cols-2 text-center">
                            {slots.map((slot, idx) =>
                                <div className={clsx("cursor-pointer p-3 font-semibold rounded-lg", { "bg-mycia-lightRed": checkbox === idx, "bg-gray-100": checkbox !== idx })} onClick={() => onSelectHandler(idx)} key={idx}>{slot.start}</div>)}
                        </div>

                        <p className="mt-4 text-center first-letter:capitalize">{t("availabilityPage.p_4")}</p>
                    </div>}

                {selected ?
                    <Link to={`/${lang}/${token}/contact`} onClick={onClickHanlder} className="block w-full mt-4 px-4 py-2 rounded-md text-center uppercase bg-mycia-red text-white">{t("availabilityPage.link_1")}</Link> :
                    null}

                {business.attributes.reservation_settings[0].minimum_seats < seats ?
                    <p className="py-2 text-center first-letter:capitalize">{t("availabilityPage.p_5", { minimum_seats: business.attributes.reservation_settings[0].minimum_seats })}</p> :
                    null}
            </div>
        </div>
    );
}

export default AvailabilityPage;
