import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useBooking } from "../contexts/booking";
import client from "../apis";
import OtpInput from "../components/OtpInput";
import { trackData } from "../apis/analytics";
import { ACTIONS } from "../utils";

function OtpPage(props) {
    const [loading, setLoading] = React.useState(false);
    const [otp, setOtp] = React.useState("");

    let navigate = useNavigate();
    const { t } = useTranslation();
    const { lang, token } = useParams();
    const { date, time, seats, zone, business, guest } = useBooking();
    const [searchParams] = useSearchParams();
    let phoneNumber = searchParams.get('phone');

    const onSubmitHandler = async evt => {
        evt.preventDefault();

        try {
            setLoading(true);
            let otpResponse = await client.otpConfirm({ phone: phoneNumber, code: otp });

            if (otpResponse && otpResponse.status === 204) {
                setLoading(true);
                //let response = await client.book(guest);
                let response = await client.bookOld({ ...guest, date: date, time: time, seats: seats, token: token, zone: zone });

                if (response && response.status === 201) {
                    trackData(
                        business.attributes?.reservation_settings?.[0]?.ris_id, token, lang, ACTIONS.REQUEST_BOOKING,
                        business.attributes?.reservation_settings?.[0]?.ris_networks,
                        response.data,
                    );
                    navigate(`/${lang}/${token}/confirm`);
                }
            }
        } catch (err) {
            alert(err);
        }
    }

    return (
        <div className="relative max-w-xl mx-auto m-4 p-8 space-y-4 rounded-md shadow-md">
            <div className="">
                <p className="flex-1 text-2xl text-center first-letter:capitalize">{t("otpPage.p_1")}</p>
            </div>

            <p className="text-2xl text-center">{business.attributes.name}</p>

            <p className="text-center">{t("otpPage.p_2")}</p>

            <form onSubmit={onSubmitHandler}>
                <fieldset>
                    <legend className="sr-only text-lg">{t("otpPage.legend_1")}</legend>

                    <OtpInput value={otp} onChange={val => setOtp(val)} />
                </fieldset>

                {loading ?
                    <button disabled type="submit" className="block w-full mt-4 px-4 py-2 rounded-md text-center uppercase bg-mycia-red text-white">
                        <div className="flex items-center justify-center gap-x-2">
                            <p>{t("otpPage.p_3")}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                <circle cx="50" cy="50" fill="none" stroke="#ffffff" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
                                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                </circle>
                            </svg>
                        </div>
                    </button> :
                    <button type="submit" className="block w-full mt-4 px-4 py-2 rounded-md text-center uppercase bg-mycia-red text-white">{t("otpPage.button_1")}</button>}
            </form>
        </div>
    );
}

export default OtpPage;
