import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useBooking } from "../contexts/booking";
import client from "../apis";
import { trackData } from "../apis/analytics";
import { ACTIONS } from "../utils";

function ContactPage() {
    const [loading, setLoading] = useState(false);

    let navigate = useNavigate();
    const { t } = useTranslation();
    const { lang, token } = useParams();
    const { date, time, seats, business, zone, setGuest } = useBooking();

    const onSubmitHandler = async evt => {
        evt.preventDefault();

        let formdata = new FormData(evt.target);
        let userdata = Object.fromEntries(formdata);
        userdata.date = date;

        try {
            setLoading(true);
            trackData(
                business.attributes?.reservation_settings?.[0]?.ris_id, token, lang, ACTIONS.SEND_GUEST,
                business.attributes?.reservation_settings?.[0]?.ris_networks,
                { phone: userdata.phone, email: userdata.email },
            );
            let response = await client.otpRegister(userdata);

            if (response && response.status === 201) {
                setGuest({
                    fname: userdata.fname,
                    lname: userdata.lname,
                    phone: userdata.phone,
                    email: userdata.email,
                });

                navigate(`/${lang}/${token}/otp?phone=${userdata.phone}`);
            }
        } catch (err) {
            alert(err);
        }
    }

    return (
        <div className="max-w-xl mx-auto m-4 p-8 space-y-4 rounded-md shadow-md">
            <div className="flex items-center">
                <Link to={`/${lang}/${token}/availability`}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6" viewBox="0 0 512 512">
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M244 400L100 256l144-144M120 256h292" />
                    </svg>
                </Link>

                <p className="flex-1 text-2xl text-center first-letter:capitalize">{t("contactPage.p_1")}</p>
            </div>

            <p className="text-2xl text-center">{business.attributes.name}</p>

            <div className="px-4 py-2 flex items-center gap-4 rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-8" viewBox="0 0 512 512">
                    <rect fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="32" x="48" y="80" width="416" height="384" rx="48" />
                    <circle cx="296" cy="232" r="24" />
                    <circle cx="376" cy="232" r="24" />
                    <circle cx="296" cy="312" r="24" />
                    <circle cx="376" cy="312" r="24" />
                    <circle cx="136" cy="312" r="24" />
                    <circle cx="216" cy="312" r="24" />
                    <circle cx="136" cy="392" r="24" />
                    <circle cx="216" cy="392" r="24" />
                    <circle cx="296" cy="392" r="24" />
                    <path fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="32" strokeLinecap="round" d="M128 48v32M384 48v32" />
                    <path fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="32" d="M464 160H48" />
                </svg>

                <span className="font-bold">{date.toLocaleString(lang, { 'weekday': 'long', 'month': 'long', 'day': '2-digit' })}</span>
            </div>

            <div className="px-4 py-2 flex items-center gap-4 rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-8" viewBox="0 0 512 512">
                    <path d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" />
                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M256 128v144h96" />
                </svg>

                <span className="font-bold">{time}</span>
            </div>

            <div className="px-4 py-2 flex items-center gap-4 rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-8" viewBox="0 0 512 512">
                    <path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                    <path d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" />
                </svg>

                <span className="font-bold">{seats} {seats > 1 ? t("contactPage.span_1_b") : t("contactPage.span_1_a")}</span>
            </div>

            {zone ? <div className="px-4 py-2 flex items-center gap-4 rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 0 1-1.125-1.125v-3.75ZM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-8.25ZM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-2.25Z" />
                </svg>

                <span className="font-bold">{zone}</span>
            </div> : null}

            <form aria-label="contact info form" onSubmit={onSubmitHandler}>
                <fieldset>
                    <legend className="sr-only text-lg">Contatti</legend>

                    <input type="hidden" name="token" value={token} />
                    <input type="hidden" name="seats" value={seats} />
                    <input type="hidden" name="time" value={time} />
                    <input type="hidden" name="zone" value={zone} />

                    <div className="mb-4">
                        <label className="sr-only">Nome</label>
                        <input aria-label="contact name" type="text" name="fname" placeholder={t("contactPage.input_1")} className="w-full px-4 py-2 rounded-md border border-gray-500 placehoder:capitalize" required autoFocus />
                    </div>

                    <div className="mb-4">
                        <label className="sr-only">Cognome</label>
                        <input aria-label="contact surname" type="text" name="lname" placeholder={t("contactPage.input_2")} className="w-full px-4 py-2 rounded-md border border-gray-500" required />
                    </div>

                    <div className="mb-4">
                        <label className="sr-only">Cellulare</label>
                        <div className="flex gap-x-4">
                            <input className="w-16 px-4 py-2 rounded-md border border-gray-500" value="+39" disabled />
                            <input aria-label="contact phone" type="text" pattern="^3\d{2}[\. ]??\d{6,7}$" name="phone" placeholder={t("contactPage.input_3")} className="w-full px-4 py-2 rounded-md border border-gray-500" title="inserire un numero di cellulare valido e senza spazi" required />
                        </div>
                    </div>

                    <div>
                        <label className="sr-only">Email</label>
                        <input aria-label="contact email" type="email" name="email" placeholder={t("contactPage.input_4")} className="w-full px-4 py-2 rounded-md border border-gray-500" required />
                    </div>
                </fieldset>

                {loading ?
                    <button disabled type="submit" className="block w-full mt-4 px-4 py-2 rounded-md text-center uppercase bg-mycia-red text-white">
                        <div className="flex items-center justify-center gap-x-2">
                            <p>prenotazione in corso</p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                <circle cx="50" cy="50" fill="none" stroke="#ffffff" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
                                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                </circle>
                            </svg>
                        </div>
                    </button> :
                    <button type="submit" className="block w-full mt-4 px-4 py-2 rounded-md text-center uppercase bg-mycia-red text-white">{t("contactPage.button_1")}</button>}
            </form>
        </div>
    );
}

export default ContactPage;
