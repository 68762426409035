import axios from "axios";

function catchError(err) {
    if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(err.response.data);
        console.log("[Response]", err.response.status);
        console.log("[Response]", err.response);
        // console.log(err.response.headers);
        return err.response;
    } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("[Request]", err.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log("[Error]", err.message);
    }
}

export async function getRaw(url, config) {
    console.log("[GET]", url);
    console.log("[-CONFIG]", config);

    try {
        return await axios.get(url, config);
    } catch (err) {
        catchError(err);
    }
}

export async function postRaw(url, data, config) {
    console.log("[POST]", url);
    console.log("[-CONFIG]", config);
    console.log("[-DATA]", JSON.stringify(data));

    try {
        return await axios.post(url, data, config);
    } catch (err) {
        catchError(err);
    }
}
