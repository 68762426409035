import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Calendar from "react-calendar";
import { useBooking } from "../contexts/booking";
import { trackData } from "../apis/analytics";
import { useTranslation } from "react-i18next";
import "../styles/calendar.css";
import { ACTIONS } from "../utils/index";

function CalendarPage() {
    const { lang, token } = useParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { setZone, setDate, setSeats, setToken, business } = useBooking();

    const [userdate, setUserdate] = React.useState(new Date());

    React.useEffect(() => {
        i18n.changeLanguage(lang);
        setDate(null);
        setSeats(null);
    }, [i18n, lang, setDate, setSeats]);

    React.useEffect(() => {
        setToken(token);
    }, [token, setToken]);

    React.useEffect(() => {
        async function log() {
            business && trackData(
                business.attributes?.reservation_settings?.[0]?.ris_id, token, lang, ACTIONS.PAGE_VIEW,
                business.attributes?.reservation_settings?.[0]?.ris_networks,
            );
        }

        log();
    }, [business, token, lang]);

    const onChangeHandler = date => {
        date.setHours(12);
        setUserdate(date);
    }

    const onSubmitHandler = evt => {
        evt.preventDefault();

        const formdata = new FormData(evt.target);
        const userdata = Object.fromEntries(formdata);

        setDate(userdate);
        setSeats(userdata.seats);
        setZone(userdata.zone);

        trackData(
            business.attributes?.reservation_settings?.[0]?.ris_id, token, lang, ACTIONS.FIND_TABLE,
            business.attributes?.reservation_settings?.[0]?.ris_networks,
            { seats: Number(userdata.seats) },
        );
        navigate(`/${lang}/${token}/availability`);
    }

    return (
        <div className="max-w-xl mx-auto m-4 p-8 space-y-8 rounded-md shadow-md">
            <p className="text-2xl text-center first-letter:capitalize">{t("calendarPage.p_1")}</p>

            <Calendar locale={lang} minDate={new Date()} prev2Label={null} next2Label={null} onChange={onChangeHandler} value={userdate} />

            <form method="get" onSubmit={onSubmitHandler}>
                <fieldset className="my-4">
                    <legend className="sr-only">{t("calendarPage.legend_1")}</legend>

                    <div>
                        <label htmlFor="seats" className="sr-only">{t("calendarPage.label_2")}</label>

                        <div className="flex gap-4 px-4 py-2 border rounded-md">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-8" viewBox="0 0 512 512">
                                <path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                <path d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" />
                            </svg>

                            <select name="seats" id="seats" className="w-full text-center bg-white">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((id, index) =>
                                    <option value={id} key={index}>
                                        {id} {id === 1 ?
                                            t("calendarPage.select_1.option_1") :
                                            t("calendarPage.select_1.option_2")}
                                    </option>)}
                            </select>
                        </div>

                        {business && business.attributes.zones && business.attributes.zones.length > 0 ?
                            <div className="mt-4 flex gap-4 px-4 py-2 border rounded-md">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 0 1-1.125-1.125v-3.75ZM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-8.25ZM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-2.25Z" />
                                </svg>

                                <select name="zone" id="zone" className="w-full text-center bg-white">
                                    {business.attributes.zones.map((zone, index) => <option value={zone.name} key={index}>{zone.name}</option>)}
                                </select>
                            </div> : null}
                    </div>
                </fieldset>

                <button type="submit" className="w-full px-4 py-2 text-center text-white uppercase rounded-md bg-mycia-red">{t("calendarPage.button_1")}</button>
            </form>
        </div>
    );
}

export default CalendarPage;
