import { getRaw, postRaw } from "./network";

//const DOMAIN_URL = "https://api2022stag.sdch.develondigital.com";
const DOMAIN_URL = "https://orders.mycontactlessmenu.mycia.it";
//const DOMAIN_URL = "http://localhost:8000";
const VERSION = "v1";

function url(path) {
    return `${DOMAIN_URL}/${path}`;
}

async function getRelated(model, relation, config) {
    return await getRaw(model.relationships[relation].links.related, config);
}

async function getAvailabilitySlots(token, date, time, seats) {
    return await getRaw(url(`${VERSION}/bookings/availability?token=${token}&date=${date}&time=${time}&seats=${seats}`), {});
}

async function getAvailabilitySlotsOld(token, date, seats) {
    return await getRaw(url(`api/bookings/availability?token=${token}&date=${date}&seats=${seats}`), {});
}

async function filter(name, token) {
    return await getRaw(url(`${VERSION}/${name}?filter[token]=${token}`), {});
}

async function getBusiness(token) {
    return await filter("businesses", token);
}

async function getBusinessOld(token) {
    return await getRaw(url(`api/businesses/${token}`));
}

async function book(userdata) {
    let [hours, minutes] = userdata.time.split(":");
    userdata.date.setHours(hours);
    userdata.date.setMinutes(minutes);

    let data = {
        data: {
            livemode: false,
            name: `${userdata.fname} ${userdata.lname}`,
            email: userdata.email,
            phone: `+39${userdata.phone}`,
            seats: Number(userdata.seats),
            token: userdata.token,
            note: userdata.zone,
            reserved_at: userdata.date,
        }
    }

    return await postRaw(url(`${VERSION}/bookings/book`), data);
}

async function bookOld(userdata) {
    let data = {
        customer: `${userdata.fname} ${userdata.lname}`,
        email: userdata.email,
        phone: `+39${userdata.phone}`,
        seats: Number(userdata.seats),
        token: userdata.token,
        note: userdata.zone,
        reserved_at: userdata.date.toISOString().split('T')[0],
        reserved_time: userdata.time,
    }

    return await postRaw(url(`api/bookings`), data);
}

async function otpRegister(userdata) {
    return await postRaw(url('api/otp/register'), userdata);
}

async function otpConfirm(userdata) {
    return await postRaw(url('api/otp/confirm'), userdata);
}

const client = {
    getRelated,
    getAvailabilitySlots,
    getAvailabilitySlotsOld,
    filter,
    getBusiness,
    getBusinessOld,
    book,
    bookOld,
    otpRegister,
    otpConfirm,
};
export default client;
